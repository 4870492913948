import '../styles/index.scss';
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

const splitText = new SplitText("h1 span i", {type: "chars"});
const chars = splitText.chars;


const tl = gsap.timeline({
	scrollTrigger: {
		trigger: ".container-gsap",
		start: "top top",
		end: "bottom top",
		scrub: 1,
		pin: true,
	}
})

tl.to(".bg", {duration: 2, clipPath: 'inset(0% 0% 0% 0%)', scale: '1.5', ease: "power2.inOut"})
tl.to(chars, {duration: 1, y: '-125%', stagger: '.01', ease: "power2.inOut"}, "-=2")
tl.to('.bottom', {duration: 1, autoAlpha: 0, x: -100, ease: "power2.inOut"}, "-=2")
tl.fromTo('.second', {duration: 1, autoAlpha: 0, x: 100, ease: "power2.inOut"}, {duration: 1, autoAlpha: 1, x: 0, ease: "power2.inOut"}, "-=1")
